import type { AppProps } from 'next/app'

import PlausibleProvider from 'next-plausible'

import { Layout } from 'components/Layout'
import { useCSRF } from 'hooks/useCSRF'

import '../styles/globals.css'

function MyApp({ Component, pageProps }: AppProps) {
  useCSRF()

  return (
    <Layout>
      <Component {...pageProps} />
      <PlausibleProvider domain="rysk.finance" revenue trackOutboundLinks />
    </Layout>
  )
}

export default MyApp
