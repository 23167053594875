import { useEffect } from 'react'

import { getCsrfToken } from 'next-auth/react'

/**
 * Generate a CSRF token for the users session.
 */
export const useCSRF = () => {
  useEffect(() => {
    const getCSRF = async () => {
      await getCsrfToken()
    }

    getCSRF()
  }, [])
}
