import { init } from '@sentry/nextjs'

type EnvironmentName = 'client' | 'edge' | 'server'

const initConfigForEnv = (environment: string, tracesSampleRate: number) => {
  init({
    attachStacktrace: true,
    dsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment,
    release: process.env.VERCEL_GIT_COMMIT_SHA || process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    tracesSampleRate,

    beforeBreadcrumb: (breadcrumb, hint) => {
      return hint?.level === 'warn' ? null : breadcrumb
    },
  })
}

const initSentry = (environmentName: EnvironmentName) => {
  const app_env = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV

  if (app_env) {
    const sampleRate = app_env === 'production' ? 0.05 : 1

    initConfigForEnv(`${app_env}: ${environmentName}`, sampleRate)
  }
}

export default initSentry
